import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Group } from './Group';
import { VendorList } from './VendorList';
import { Search } from './Search';

export class Configure extends Component {
    displayName = Configure.name;
    
    constructor(props) {
        super(props);
        this.setSearchRef = this.setSearchRef.bind(this);
        this.handleClickOutsideSearch = this.handleClickOutsideSearch.bind(this);
        this.onSearch = this.onSearch.bind(this);

        

        this.state = {
            activeComponent: PropTypes.string.isRequired,
            activeCategory: PropTypes.string.isRequired,
            activeProperty: PropTypes.string.isRequired,
            searchFocused: false
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideSearch);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideSearch);
    }

    setSearchRef(node) {
        this.searchRef = node;
    }

    handleClickOutsideSearch(event) {
        const domNode = ReactDOM.findDOMNode(this.searchRef);
        if (domNode && !domNode.contains(event.target)) {
            this.setState({ searchFocused: false });
        }
    }

    setFocus = (focus) => {
        this.setState({ searchFocused: focus });
    }

    onSearch = (item) => {
        let property = this.props.properties.find((prop) => {
            return prop.Name === item;
        });

        let component = property.ComponentId;

        let category = property.CategoryIds.find((id) => {
            return id !== "Popular" && id !== "All";
        });

        this.setState({
            activeComponent: component,
            activeCategory: category,
            activeProperty: property.Id
        });
    }

    handleUserInput = (event) => {
        this.setState({ filterText: event.target.value });
    }

    onClick = (item) => {
        this.setState({
            activeComponent: item.component,
            activeCategory: item.category,
            activeProperty: item.property
        });
    }

    onCheck = (item) => {
        const { addToBasket, removeFromBasket } = this.props;
        if (this.props.selectedProperties.indexOf(item) === -1) {
            addToBasket(item);
        } else {
            removeFromBasket(item);
        }
    }

    getOptions = () => {
        return (
            <div className="g-panel g-panel--25">
                <ul className="g-panel__list">
                    {this._components}
                </ul>
            </div>
        );
    }

    render() {
        var selectedProperties = this.props.selectedProperties;
        var vendors = this.props.vendors;
        let activeComponent = this.state.activeComponent;
        let selectedVendors = this.props.selectedVendors;

        this._components = this.props.components.map((component, i) => {
            if (selectedVendors.some(s => component.VendorIds.indexOf(s) !== -1)) {
                let _active = false;
                if (component.Id === activeComponent) {
                    _active = true;
                }
                return (
                    <Group
                        key={i}
                        component={component}
                        categories={this.props.categories}
                        properties={this.props.properties}
                        examples={this.props.examples}
                        vendors={vendors}
                        onClick={this.onClick.bind(this)}
                        onCheck={this.onCheck.bind(this)}
                        availableProperties={this.props.availableProperties}
                        selectedProperties={selectedProperties}
                        selectedVendors={selectedVendors}
                        active={_active}
                        activeCategory={this.state.activeCategory}
                        activeProperty={this.state.activeProperty}
                    />);
            }
            return undefined;
        });

        return (
            <main className="g-main">
                <section className="g-search">
                    <Search
                        ref={this.setSearchRef}
                        focused={this.state.searchFocused}
                        properties={this.props.properties}
                        vendors={vendors}
                        setFocus={this.setFocus}
                        onSearch={this.onSearch}
                    />
                    <VendorList
                        vendors={vendors}
                        selectedVendors={selectedVendors}
                        onChange={this.props.onSelectVendors}
                    />
                </section>
                <section className="g-options">
                    {this.getOptions()}
                </section>
            </main>
        );
    }
}