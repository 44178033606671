import React, { Component } from 'react';
export class ReferrerImplementation extends Component {
    displayName = ReferrerImplementation.name;

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        const { handleClick } = this.props;
        handleClick('');
    };

    copyCode = (e) => {
        e.preventDefault();
        this.copy(this.getCode());
    };

    copy = (data) => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = data;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert('Copied!');
    };

    getCode = () => {
        return this.props.resource.key;
    };

    scrollToTop = () => {
        if (this.refs.scroller) {
            this.refs.scroller.scrollTop = 0;
        }
    };

    render() {
        let classes = "g-panel g-panel--75";

        if (this.props.active) {
            classes += " is-active";
        }

        let referrer = () => {
            if (this.props.referer !== '') {
                return (
                    <p>Return to: <a href={this.props.referer} target="blank">{this.props.referer}</a></p>
                );
            }
        };

        return (
            <div className={classes}>
                <header className="c-category-header">
                    <a href="#" className="c-category-header__link is-reversable" onClick={this.handleClick}>Back</a> / Server side
                </header>

                <article className="g-info" ref="scroller">
                    <h2 className="g-info__title">Resource Key</h2>
                    <nav className="c-sub-nav">
                        <h3 className="c-sub-nav__heading">On this page</h3>
                        <p>This is your personal 51Degree Cloud Resource Key, keep it somewhere safe. For additional cloud implementation examples see:</p>
                        <ul className="c-sub-nav__list">
                            <li className="c-sub-nav__link"><a href="https://docs.51degrees.com" className="c-sub-nav__item" target="blank">51Degrees V4 Documentation</a></li>
                            <li className="c-sub-nav__link"><a href="https://cloud.51degrees.com/api-docs/" className="c-sub-nav__item" target="blank">51Degrees Cloud Documentation</a></li>
                        </ul>
                    </nav>
                    <div className="c-code">
                        <header className="c-code__header" id="example1">
                            <h2 className="c-code__heading">Resource Key</h2>
                            <a href="#" className="c-copy-clipboard" onClick={this.copyCode}><img src="/images/icon-clipboard.svg" alt="Copy to clipboard" /></a>
                        </header>
                        <code className="c-code__block">
                            {this.getCode()}
                        </code>
                    </div>
                    <div className="g-info__description">
                        {referrer()}
                    </div>
                    <a className="c-top-link hide" href="#" id="js-top" onClick={(e) => { e.preventDefault(); this.scrollToTop(); }}>
                        <img src="/images/back-to-top.svg" alt="Back to top" className="c-top-link__image" />
                        <span className="c-top-link__label">Back to top</span>
                    </a>
                </article>
            </div>);
    }
}
