import React, { Component } from 'react';

export class PropertyInfo extends Component {
    displayName = this.props.property.Name;
     

    constructor(props) {
        super(props);
        this._handleCheck = this._handleCheck.bind(this);

        this.state = {
            propertyValue : "Null"
        };
    }

    componentDidMount() {
        var property = this.props.property;

        window.fod.complete((data) => {
            var value = data[property.VendorId][property.Id.split('.')[1].toLowerCase()];
            this.setState({
                propertyValue: value
            });
        }, property.VendorId);
    }

    _handleCheck() {
        const { property, onCheck } = this.props;
        onCheck(property.Id);
    }

    render() {
        if (this.props.active) {
            const property = this.props.property;
            const examples = this.props.examples;
            var propertyExamples = ((examples[property.VendorId] === null || examples[property.VendorId] === undefined) ? undefined : examples[property.VendorId]);
            var propertyValue = "Null";

            if (this.state.propertyValue === null || this.state.propertyValue === undefined) {
                propertyValue = "Null";
            } else {
                if (Array.isArray(this.state.propertyValue)) {
                    if (this.state.propertyValue.some(s => typeof (s) == "object")) {
                        propertyValue = this.state.propertyValue.map(v => v["value"]).filter(v => v != "Unknown").toString()
                    } else {
                        propertyValue = ((this.state.propertyValue === null || this.state.propertyValue === undefined) ? "Null" : this.state.propertyValue.toString());
                    }
                } else if (typeof (this.state.propertyValue) == "object") {
                    var tmp = []
                    for (const [key, value] of Object.entries(this.state.propertyValue)) {
                        tmp.push(`${key}: ${value}`);
                    }
                    propertyValue = tmp.join(', ');
                } else {
                    propertyValue =  this.state.propertyValue.toString();
                }
            }
            

            return (
                <div className={`g-panel g-panel--25  ${this.props.active ? "is-active" : ""}`}>
                    <header className="c-category-header"><a className="c-category-header__link is-reversable" onClick={this.props.back}>{this.props.category.Name}</a> / {this.displayName}</header>
                    <article className="g-info">
                        <header className="g-info__header">
                            <h3 className="g-info__heading">
                                {this.displayName}
                                <span className="c-option__additional">
                                    <span className="c-option__vendor"><img src={this.props.vendor.iconUrl} alt={this.props.vendor.name} width="13" height="13" /></span>
                                    {this.props.isBillable ? <span className="c-option__billable"><span>Billable</span></span> : null}
                                </span>
                            </h3>
                            <h2 className="g-info__by">By {this.props.vendor.name}</h2>
                        </header>
                        <div className="g-info__logo">
                            <img src={this.props.vendor.imageUrl} alt={this.props.vendor.name} />
                        </div>
                        {this.props.isBillable ?
                            <p className="g-info__banner">
                                <span className="c-option__billable">
                                    <span>Billable</span>
                                </span>
                                Paid feature, use free for 30 days
                            </p>
                            : null}
                        <span className="c-select c-select--alternative c-select--large">
                            <input type="checkbox" className="c-select__input is-selectable" id={`${this.displayName}--alternative`} data-property={this.displayName} onChange={this._handleCheck} checked={this.props.checked} />
                            <label className="c-select__label" htmlFor={`${this.displayName}--alternative`}><span className="c-select__text">Select this property</span></label>
                        </span>
                        <p className="g-info__description">{this.props.property.Description}</p>
                        {(propertyExamples !== undefined) ?
                            <div className="c-outputs">
                                <div className="c-outputs__header">
                                    <h3 className="c-outputs__heading">Outputs:</h3>
                                </div>
                                <table className="c-outputs__table">
                                    <thead>
                                        <tr>
                                            <th>Device</th>
                                            <th>Example response</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(propertyExamples).map((example, i) => {
                                            var value = "Null";

                                            if (example === "This Device") {
                                                if (propertyValue === "true" || propertyValue === "false") {
                                                    value = propertyValue.charAt(0).toUpperCase() + propertyValue.slice(1);
                                                } else {
                                                    value = propertyValue;
                                                }
                                            } else {
                                                value = propertyExamples[example][property.Id.split('.')[1].toLowerCase()];
                                            }

                                            return (
                                                [<tr key="head{i}">
                                                    <th className="c-outputs__device">{example}:</th>
                                                </tr>,
                                                <tr key="standard{i}">
                                                    <td><span ref={example.replace(/\s/g, '').toLowerCase()} className="c-outputs__example">{value}</span></td>
                                                </tr>]
                                            );
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        : null}
                    </article>
                </div>
            );
        } else {
            return null;
        }
    }
}